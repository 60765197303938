<template>
  <div class="content-box">
    <a-modal centered :width='800' v-model="showModal" title="聊天记录">
      <div class="take-box">
        <a-table
            @change="changePageModal"
            :pagination='paginationModal'
            :loading='loadingModal'
            :columns='takeColumns'
            :data-source='chatList'
            :rowKey="(record,n)=>n"
        >
          <div slot="content" slot-scope="row">
            <div v-if="row.msgType === 'TIMTextElem'">{{row.msg}}</div>
            <img v-if="row.msgType === 'TIMImageElem'" style="max-width:100px;max-height:100px;" :src="row.msg" alt="" @click="previewImg(row)">
            <div class="width-100" v-if="row.msgType === 'TIMSoundElem'">
              <audio controls>
                <source :src="row.msg">
              </audio>
            </div>
            <div v-if="row.msgType === 'TIMVideoFileElem' && row.msg">
              <video controls width="200">
                <source :src="row.msg" type="video/mp4">
              </video>
            </div>
            <div v-if="row.msgType === 'TIMVideoFileElem' && !row.msg" >{{ '抱歉！当前视频路径未找到' }}</div>
          </div>
          <div slot="contentTypeSlot" slot-scope="row">
            <div style='color:green'>{{ contentTypeMapText[row.msgType] }}</div>
          </div>
        </a-table>
      </div>
      <div class="footer-btn" slot="footer">
        <a-button @click="cancel">关闭</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      loadingModal: false,
      groupId: '',
      oneUserId:'',
      twoUserId:'',
      chatList: [],
      takeColumns:[
        {title:'昵称',dataIndex:'userName'},
        {title:'内容',width:400,scopedSlots: { customRender: 'content' },},
        {title:'类型',width:100,align:'center', customRender:(text, row, index) => {
            const {msgType} = row
            const inner = msgType === 'TIMTextElem' ? '文本' : (msgType === 'TIMImageElem' ? '图片' : '语音')

            return <span style='color:green'>{inner}</span>
          }},
        {title:'时间',width:'200',align:'center',dataIndex:'sendTime'},
      ],
      paginationModal:{
        total: 0,
        current: 1,
        showTotal:(total)=>`共${total}条`,
      },
      modalParams: {
        pageNum: 1,
        pageSize: 20,
        groupId: ''
      }
    }
  },
  methods: {
    /** 显示 */
    async show(groupId,oneUserId,twoUserId) {
      this.groupId = JSON.parse(JSON.stringify(groupId))
      this.oneUserId = JSON.parse(JSON.stringify(oneUserId))
      this.twoUserId = JSON.parse(JSON.stringify(twoUserId))
      this.modalParams.groupId = this.groupId
      this.modalParams.oneUserId = this.oneUserId
      this.modalParams.twoUserId = this.twoUserId
      await this.getUserChatMessage()
      this.showModal = true
    },
    /** 关闭 */
    cancel() {
      this.showModal = false
      this.modalParams.pageNum = 1
      this.modalParams.groupId = ''
      this.paginationModal = {}
      this.chatList = []
    },
    /** 获取聊天信息 */
    async getUserChatMessage() {
      this.loadingModal = true
      const res = await this.axios('/dq_admin/groupChat/getByGroupIdV2',{
        params: this.modalParams
      })
      this.loadingModal = false
      if (res.status != 200) return
      let temp = res.data
      this.chatList = temp.records
      this.paginationModal.total = temp.total
    },
    /** 翻页 */
    async changePageModal(page){
      const {current} = page
      this.paginationModal.current = current
      this.modalParams.pageNum = current
      await this.getUserChatMessage()
    },
    previewImg(row){
      const arrImg = []
      this.chatList.forEach(el => {
        if (el.msgType === 'TIMImageElem') {
          arrImg.push({img_url: el.msg})
        }
      })
      const foundIndex = arrImg.findIndex(el => el.img_url === row.msg)
      if(row.msg&&row.msg.length>0){
        this.$previewImg({
          list: arrImg,
          baseImgField:'img_url',
          showMute:false,
          current: foundIndex
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  width: 800px;
}
.take-box{width:100%;}
</style>