<template>
  <div class="search-big-small-version-coin-pages">
    <slot name="searchParamsBefore"></slot>
    <!-- 选择大币种 -->
    <a-select
        :dropdownMatchSelectWidth="false"
        @change="changeCoin"
        @search="coinSearch"
        :filter-option="untils.filterOption"
        allowClear
        showSearch
        :size="boxSize"
        class="w-150 ml-10"
        v-model="params.coinKindId"
        placeholder="请选择大币种"
    >
      <a-select-option
          v-for="item of bigCoinList"
          :key="item.id"
          :value="item.id"
      >{{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
    </a-select>
    <!-- 选择小币种 -->
    <a-select
        :dropdownMatchSelectWidth="false"
        @change="changeCoinItem"
        :filter-option="untils.filterOption"
        allowClear
        showSearch
        :size="boxSize"
        class="w-150 ml-10"
        v-model="params.coinKindItemId"
        placeholder="请选择小币种"
    >
      <a-select-option
          v-for="item of smallCoinList"
          :key="item.id"
          :value="item.id"
      >{{ item.coinKindItemName }}</a-select-option>
    </a-select>
    <!-- 选择版别 -->
    <a-select
        :dropdownMatchSelectWidth="false"
        allowClear
        showSearch
        :filter-option="untils.filterOption"
        class="w-250 ml-10"
        :size="boxSize"
        v-model="params.coinKindItemVersionId"
        :dropdownMenuStyle="{'max-height': '500px'}"
        placeholder="版别"
    >
      <a-select-option
          v-for="item of versionList"
          :key="item.coinVersionId"
          :value="item.coinVersionId"
      >{{ `【${item.level ?  item.level : "无"}】 ${item.coinVersionName}(${item.size || 0})` }}</a-select-option>
    </a-select>
    <slot name="searchParamsAfter"></slot>
  </div>
</template>
<script>
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"
import untils from "@/untils"

export default {
  mixins: [_bigOrSmallCoinByVersion],
  props: ['params', 'boxSize', 'versionSearchType'],
  data() {
    return {
      untils
    }
  },
  methods: {
    /** 搜索大币种 */
    async getSearchBigCoinByCoinName(coinName) {
      await this.coinSearch(coinName, 'search')
    },
  }
}
</script>
<style scoped lang="scss">
.search-big-small-version-coin-pages {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
</style>